import { CustomWindow } from './models/customwindow';

declare let window: CustomWindow;

export const useGeneral = () => {
  const loadImage = (imageElement: HTMLImageElement): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      imageElement.onload = () => {
        resolve(imageElement);
      };

      if (imageElement.complete) {
        resolve(imageElement);
      }

      imageElement.onerror = reject;
    });
  }

  const closeOnEsc = (event: KeyboardEvent, closeFunction: () => void): void => {
    if (event.key !== 'Escape') {
      return;
    }
    
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      closeFunction();
    }

    event.preventDefault();
  }

  const getScrollbarWidth = (): number => {
    const tmpDiv = document.createElement('div') as HTMLDivElement;
    tmpDiv.style.visibility = 'hidden';
    tmpDiv.style.overflow = 'scroll';
    document.body.appendChild(tmpDiv);
    const inner = document.createElement('div');
    tmpDiv.appendChild(inner);
    const scrollbarWidth = (tmpDiv.offsetWidth - inner.offsetWidth);
    document.body.removeChild(tmpDiv);

    return scrollbarWidth;
  }

  const trapFocus = (event: KeyboardEvent, focusableElements: NodeListOf<HTMLElement>, additionalCondition: Boolean | undefined): void => {
      const isTabPressed = event.key === 'Tab';
      if (!isTabPressed || event.defaultPrevented || !additionalCondition) {
        return;
      }

      const focusableContent = Array.from(focusableElements) as HTMLElement[];
      const filteredFocusableContent = focusableContent.filter(element => element.offsetHeight > 0);

      const firstFocusableElement = filteredFocusableContent[0] as HTMLElement;
      const lastFocusableElement = filteredFocusableContent[filteredFocusableContent.length - 1] as HTMLElement;

      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          event.preventDefault();
        }
      } else if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        event.preventDefault();
      }
    }

  return {
    loadImage,
    closeOnEsc,
    getScrollbarWidth,
    trapFocus
  };
};

window.addEventListener('CookiebotOnLoad', () => {
  if (!document.body.classList.contains("cookiebot-loaded")) {
    document.body.classList.add("cookiebot-loaded");
  }
});